const HERE_API_ID = process.env.GATSBY_HERE_API_ID;
const HERE_APP_CODE = process.env.GATSBY_HERE_APP_CODE;

// console.log('HERE_API_ID :', HERE_API_ID);

// const getCityNamebyCoords = coords => {
//   const latLng = coords.latitude + ',' + coords.longitude;
//   const gApiKey = 'AIzaSyAIkuqEmIf-5QaDhkxOVAwlNTmbswn4aaY';
//   const reqString = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng}&key=${gApiKey}`;
//   const response = fetch(reqString)
//     .then(res => res.json())
//     .then(resJson => {
//       if (resJson['status'] === 'OK') {
//         const results = resJson['results'][0];
//         const r2 = results.address_components
//           .filter(item => item.types.includes('locality'))
//           .map(item => item.long_name);
//         console.log('GOOGLE getCityName by Coords r2: ', r2);
//         return r2[0];
//       } else {
//         console.log('STATUS : ', resJson['status']);
//         throw new Error;
//       }
//     })
//     .catch(err => console.log('err', err));

//   return response;
// };

const getLocationByCityname = searchString => {
  // console.log('searchString (getlocationByCitname):', searchString);
  const reqString = `https://geocoder.api.here.com/6.2/geocode.json?app_id=${HERE_API_ID}&app_code=${HERE_APP_CODE}&searchtext=${searchString}`;
  const response = fetch(reqString)
    .then(res => res.json())
    .then(resJson => {
      // console.log('resJson :', resJson);
      if (resJson.Response.View.length > 0) {
        const cities = resJson['Response'].View[0].Result.map(result => result.Location);
        // console.log('cities :', cities);
        // console.log('cities.length :', cities.length);
        return cities;
      } else {
        console.log('response empty', resJson);
      }
    })
    .catch(err => {
      console.log('err catched HERE', err);
    });

  return response;
};

export { getLocationByCityname };
