import React from "react"
import { Link } from "gatsby"
import navbarStyles from "../styles/navBar.module.scss"

import '../styles/navbar.scss';
import MenuToggler from "./menuToggler";

const NavBar = ({isActive}) => {
  return (
    <>
      <nav className="navbar">
        {/* <div className="burger-btn">
          <span className="sr-only">Menu</span>
          <span className="burger-icon"></span>
        </div> */}
        <MenuToggler />
        <ul className="main-menu">
          <li className="menu-item">
            <Link to="/">Home</Link>
          </li>
          <li className="menu-item">
            <Link to="/about">About</Link>
          </li>
          {/* <li className="menu-item">
            <Link to="/providerList">Providerlist</Link>
          </li> */}
          <li className="menu-item">
            <Link to="/provider-list">Provider</Link>
          </li>
          {/* <li className="menu-item">
            <Link to="/page-2">Page 2</Link>
          </li>
          <li className="menu-item">
            <Link to="/bikecity">Bikecity</Link>
          </li> */}

        </ul>
      </nav>
    </>
  )
}

export default NavBar
