import React from 'react'

const LoadingIndicator = () => {
  return (
    <div
        className='overlay'
        style={{
        position:'absolute',
        top: '1em',
        left: '1em',
        right: '1em',
        bottom: '1em',
        background: 'rgba(245,235,255,.3)',
        zIndex: 1000,
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'

    }}>
        <div className="indicator"
            style={{
                textAlign: 'center',
                // height: '100%',
                // flex: 1,
                fontSize: '5em'
            }}>
                Loading ...

        </div>
    </div>
  )
}

export default LoadingIndicator
