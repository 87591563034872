import React, { useState, useEffect } from "react"

// import '../styles/locationSearchform.scss';
import "../styles/locationsSearchForm.scss"
import { getLocationByCityname } from "../api/geocoder"

const LocationSearchForm = ({ locationSearchHandler }) => {
  const [searchState, setSearchState] = useState({
    searchString: "",
    searchResults: [],
    coords: null,
    errorMessage: "",
    isDropdownVisible: false,
  })

  useEffect(() => {
    locationSearchHandler(searchState.coords)
  }, [searchState.coords])
  const changeHandler = e => {
    setSearchState({ ...searchState, searchString: e.target.value })
  }

  const onSubmitHandler = e => {
    e.preventDefault()
    getLocationByCityname(searchState.searchString)
      .then(res => {
        if (!res) {
          throw "no results"
        }

        const coords =
          res.length === 1
            ? {
                lat: res[0].DisplayPosition.Latitude,
                lng: res[0].DisplayPosition.Longitude,
              }
            : searchState.coords

        setSearchState({
          ...searchState,
          coords,
          errorMessage: null,
          searchResults: res,
          isDropdownVisible: res.length > 1 ? true : false,
        })
      })
      .catch(err => {
        setSearchState({
          ...searchState,
          searchResults: [],
          errorMessage: err,
          isDropdownVisible: false,
        })
      })
    // locationSearchHandler(searchString)
  }

  const dropdownClickHandler = e => {
    const locationId = e.target.id
    const selectedLocation = searchState.searchResults.filter(item => {
      return item.LocationId === locationId
    })[0]

    if (selectedLocation) {
      setSearchState({
        ...searchState,
        searchResults: [selectedLocation],
        coords: {
          lat: selectedLocation.DisplayPosition.Latitude,
          lng: selectedLocation.DisplayPosition.Longitude,
        },
        isDropdownVisible: false,
        errorMessage: "",
      })
    }
  }
  
  const activeDropdownStyle = {
    maxHeight: searchState.isDropdownVisible ? "70vh" : 0,
    opacity: searchState.isDropdownVisible ? 0.8 : 0,
  }

  return (
    <form
      className="search-form"
      onSubmit={onSubmitHandler}
      onChange={changeHandler}
    >
      <div role="search" className="location-search-form-group">
        <label htmlFor="location-search-input" className="search-label sr-only">
          Search for vehicles on location or address
        </label>
        <div className="input-wrapper">
          <input
            type="text"
            id="location-search-input"
            className="location-search-input"
          />
          <input
            type="submit"
            className="location-search-button"
            value="search"
          />
        </div>
        {searchState.searchResults.length > 1 && (
          <div
            className={"location-search-dropdown"}
            style={activeDropdownStyle}
          >
            <ul>
              {searchState.searchResults.map(item => (
                <li key={item.LocationId} className="dropdown-list-item">
                  <a
                    href="#"
                    className="dropdown-list-link"
                    id={item.LocationId}
                    onClick={dropdownClickHandler}
                  >
                    {item.Address.Label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}

        {searchState.errorMessage && (
          <div className="alert alert-error">{searchState.errorMessage}</div>
        )}
      </div>
    </form>
  )
}

export default LocationSearchForm
