import React from "react"
import styles from "../styles/MapItemDetails.module.scss"
// import GetButton from './getButton';
import Img from "gatsby-image"

import {
  FaCog,
  FaBicycle,
  FaBolt,
  FaChild,
  FaDollyFlatbed,
  FaMotorcycle,
  FaPeopleCarry,
  FaSearchLocation,
  FaShippingFast,
} from "react-icons/fa"

const MapItemDetails = props => {
  const nwDetails = props.currentNetworkDetails
  const moreDetails = props.moredetails
  const vehicle = props.activeVehicle
  // let display = props.isvisible ? '' : 'none';
  let height = props.isvisible ? "auto" : "0"
  let hide = props.isvisible ? " " : "hide"

  const closeClickHandler = e => {
    e.stopPropagation()
    // console.log('----------e: ', e);
    // console.log('props', props);
    props.setDetailVisibility(false)
  }

  const attributeIcons = attr => {
    switch (attr) {
      case "GEARS":
        return <FaCog />
        break
      case "ELECTRIC":
        return <FaBolt />
        break
      case "CHILD_SEAT":
        return <FaChild />
        break
      case "CARGO":
        return <FaShippingFast />
        break
      case "TANDEM":
        return <FaPeopleCarry />
        break

      default:
        return attr
        break
    }
  }

  return (
    <div className={styles.mapItemDetails + hide} style={{ height }}>
      <button
        className={`close-nw-details ${styles.closebtn}`}
        onClick={closeClickHandler}
      >
        <span className="sr-only">Close</span>
      </button>

      {vehicle && (
        <div className={"vehicle-details "}>
          <div className={"network-name " + styles.vehicleDetails}>
            {vehicle && vehicle.image && (
              <div className="provider-logo">
                <Img
                  style={{ width: 43 }}
                  width="40"
                  //  height="120"
                  aspectRatio="1"
                  fluid={vehicle.image[0].childImageSharp.fluid}
                  maxWidth="200"
                  // sizes={vehicle.image[0].childImageSharp.fluid
                />
              </div>
            )}
            <span className={styles.providerName}>{vehicle.provider.name}</span>
            {vehicle && vehicle.attributes && (
              <div className={styles.vehicleType}>
                <span className="value">{vehicle.type}</span>
                {vehicle.attributes.map(attr => (
                  <span className={styles.attribute} key={attr}>
                    {attributeIcons(attr)}
                  </span>
                ))}
              </div>
            )}
          </div>

          <div className={styles.links}>
            <a href={vehicle.provider.website} className="website-link">
              Website
            </a>
            {vehicle.provider.app && (
              <a href={vehicle.provider.app.ios} className="ios-deeplink">
                iOS App
              </a>
            )}
            {vehicle.provider.app && (
              <a
                href={vehicle.provider.app.android}
                className="android-deeplink"
              >
                Android App
              </a>
            )}
            {/* <a href={vehicle.provider.deepLink.android} className="android-deeplink">Android App</a> */}
          </div>
        </div>
      )}

      {/* {nwDetails && (
        <div className="network-details">
          <div className="network-name">
            <span className="{styles.label}">NetworkName: </span>
            <span className="name">{nwDetails.name}</span>
          </div>
          <div className="city-name">
            <span className="{styles.label}">City: </span>
            <span className="name">
              {nwDetails.location.city}
              <span className="country">({nwDetails.location.country})</span>
            </span>
          </div>

            <div className="more-details">
              <span className="stations label">Stations: {moreDetails && moreDetails.stations.length}</span>
            </div>
        </div>
      )} */}

      {/* {(props.activeStation) && (
        <div className="station-details">
          {props.selectedCity && <strong>{props.selectedCity} </strong>}
          <div><strong>StationName: </strong> {props.activeStation.name}</div>
          <div><strong>FreeBikes: </strong> {props.activeStation.free_bikes}</div>
          <div><strong>Slots: </strong> {props.activeStation.extra.slots}</div>
        </div>
      )} */}
    </div>
  )
}

export default MapItemDetails