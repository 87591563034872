import React, { useState } from "react"
import btnStyles from "../styles/menuToggler.module.scss"

const MenuToggler = ({ clickHandler, isMenuOpen }) => {
  // const [ isMenuOpen, setIsMenuOpen ]= useState(false);
  // console.log("ismenuopen in menutoggler: ", isMenuOpen)

  const openClass = +isMenuOpen ? " open" : ""

  return (
    // <button className={[btnStyles.burgerBtn + openClass]} onClick={() => clickHandler()}>
    //     <span className="sr-only">Menu</span>
    //     <span className={btnStyles.burgerIcon}></span>
    // </button>

    <>
      <input type="checkbox" name="menu-toggle" id="menu-toggle" className="menu-toggle"/>
      <label htmlFor="menu-toggle" className="label-toggle">
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </label>
    </>
  )
}

export default MenuToggler
