import { Link } from "gatsby"
import logo from "../images/brm-logo05-1.svg"
import PropTypes from "prop-types"
import React from "react"
import NavBar from "./navbar"
import MenuToggler from "./menuToggler"

const Header = ({ siteTitle }) => {

  return (
    <header className="header">
      <div
        className="header__inner-container"
        style={{
          margin: "0 auto",
          maxWidth: 980,
          display: "flex",
          flexWrap:'wrap',
          // justifyContent: "center",
          // alignItems: "center",
          padding: ".5rem 1.0875rem",
          // textAlign: "center",
        }}
      >
        <span
          className="logo-container"
          style={{
            width: 60,
            height: 60,
            marginRight: ".8em",
            // display: "inline-block"
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{
              marginBottom: 0,
            }}
          />
        </span>
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `.85rem 1.0875rem`,
          }}
          className="site-title"
        >
          <h1 style={{ margin: 0 }}>
            <Link
              to="/"
              style={{
                textDecoration: `none`,
              }}
            >
              {siteTitle}
            </Link>
          </h1>
        </div>
        {/* <MenuToggler clickHandler={handleMenuToggle} isMenuOpen={isMenuOpen} /> */}
        <NavBar />
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
