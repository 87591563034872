import React from "react"

const Footer = () => {
  return (
    <footer className="footer">
      © {new Date().getFullYear()}, Bikerentalmap 
    </footer>
  )
}

export default Footer
