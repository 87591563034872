import React from "react"
import { graphql } from "gatsby"
import gql from "graphql-tag"
import { Query } from "react-apollo"
import { Map, TileLayer } from "react-leaflet"
import L from "leaflet"
import "../styles/bikemap.module.scss"
// import { getBikesQuery } from '../graphql/getBikes';
import DefaultMarker from "./Marker/defaultMarker"
import McMarker from "./Marker/mcMarker"
import MapItemDetails from "./MapItemDetails"
import LoadingIndicator from "./LoadingIndicator"
import GET_MC_VEHICLES_QUERY from "../graphql/getMcVehicles"
import LocationSearchForm from "./locationSearchForm"
import { getLocationByCityname } from "../api/geocoder"

// import { graphql } from "gatsby"

const numDigits = 6
const tileProvider = {
  url:
    "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png",
  attribution:
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
  subdomains: "abcd",
  maxZoom: 19,
}

class GeneralMap extends React.Component {

    state = {
      update: 0,
      loading: false,
      error: false,
      region: {
        lat: 52.51,
        lng: 13.42,
        cityName: "undefined yet",
        zoom: 15,
      },
      isNwDetailsVisible: false,
      currentNetworkDetails: {
        location: {
          city: "probably Berlin",
        },
      },
      activeVehicle: {
        image: null,
        name: '',
        provider: {
          name: ''
        }
      },
      // tileProvider: tileProvider,
      // ...this.props.tileProvider
    }
  

  handleMarkerClick(e, vehicle) {
    // console.log("handlemarkerClick: ", e)
    // console.log("vehicle: ", vehicle)

    this.setState({
      activeVehicle: {
        ...vehicle,
        image: (vehicle && vehicle.provider && this.props.providerImages.nodes.length ) && this.props.providerImages.nodes.filter(
          item => item.name === vehicle.provider.slug
        ) || '',
      },
    })

    // console.log('this.state.activeVehicle :', this.state.activeVehicle);
    this.setState({ isNwDetailsVisible: true })

    // this.fetchBikedata();
    // this.setState({ isNwDetailsVisible: true })
    // console.log("state:", this.state)
  }

  setDetailVisibility = isVisble => {
    // console.log("setdetails visibility in generalmap")
    return this.setState({ isNwDetailsVisible: isVisble })
  }

  onViewportChanged = e => {
    // console.log('onViewportChanged: ', e);
    // console.log('lat: ', Number(e.center[0].toFixed(numDigits)))
    // console.log(Number(e.center[1].toFixed(numDigits)))

    this.setState(state => {
      return {
        // update: state.update + 1,
        region: {
          // ...state.region,
          lat: Number(e.center[0].toFixed(numDigits)),
          lng: Number(e.center[1].toFixed(numDigits)),
        },
      }
    })
  }

  onMapChange = e => {
    // console.log("onmapchange", this.state)
    // console.log("onmapchange e: ", e)
  }

  onZoomEnd = e => {
    // console.log("zoomend", e)
    // this.setState({ region: { zoom: 15 } })
  }

  fetchBikedata = () => {
    console.log("fetchbikedata")
  }

  setNewMapRegion = newCoords => {
    // console.log('locationSearchhandler from generalmap => string: ', newCoords);

    if (newCoords) {
      this.setState(state => ({
        region: {
          ...state.region,
          lat: Number(newCoords.lat.toFixed(numDigits)),
          lng: Number(newCoords.lng.toFixed(numDigits)),
        },
      }))
    }
    // console.log('coords :', coords);
    // query here api with search string
    // handle response
    // set state (cityname, geocoords
    // fetch vehicle data
  }

  componentDidMount() {
    // this.fetchBikedata()
    if (this.leafletmap) {
      const leafletMap = this.leafletmap.leafletElement
      leafletMap.on("moveend", e => this.onMapChange(e))
      // leafletMap.on("zoomend", e => this.onZoomEnd(e))

      // console.log('leafletMap.getCenter() :', leafletMap.getCenter());
    }
  }

  shouldComponentUpdate(prevState, nextState) {
    // console.log("should compom update prevState: ", prevState)
    // console.log("should compom update nextState: ", nextState)

    // update when visbility status of details changes
    if (
      nextState.isNwDetailsVisible !== this.state.isNwDetailsVisible ||
      nextState.activeVehicle !== this.state.activeVehicle
    ) {
      return true
    }

    if (this.leafletmap) {
      // const leafletMap = this.leafletmap.leafletElement

      if (
        // TODO: ignore minimal changes
        Math.abs(nextState.region.lat - this.state.region.lat) < 0.002 &&
        Math.abs(nextState.region.lng - this.state.region.lng) < 0.002
        // leafletMap.getCenter() === this.state.region
        // nextState.region === this.state.region
      ) {
        // console.log("Next and current state region equal >>> no update component: ")
        // console.log("viewport: ", this.leafletMap)

        return false
      } else {
        // console.log('triggerupdate >> NEXTSTATE: ', nextState)
        // console.log('this.state.region :', this.state.region);
        return true
      }
    }

    // return false
  }

  componentDidUpdate() {
    // console.log("this.state componentDidUpdate: ", this.state)
  }

  render() {
    // console.log("this.props :", this.props)
    return (
      <div className="bikemap-component-wrapper">
        <LocationSearchForm locationSearchHandler={this.setNewMapRegion} />
        {typeof window !== "undefined" && (
          <Map
            style={{ height: "100%", minHeight: "400px", width: "100%" }}
            ref={m => (this.leafletmap = m)}
            // center={[
            //   this.state.region.lat || this.state.userLocation.lat,
            //   this.state.region.lng || this.state.userLocation.lng
            // ]}
            center={[this.state.region.lat, this.state.region.lng]}
            onViewportChange={this.onViewportChange}
            onViewportChanged={this.onViewportChanged}
            zoom={this.state.region.zoom}
            maxZoom={20}
            attributionControl={true}
            zoomControl={true}
            doubleClickZoom={true}
            scrollWheelZoom={true}
            dragging={true}
            animate={true}
            easeLinearity={0.55}
            className="map-container flex-grow-1"
            // onClick={e => this.handleMapClick(e)}
          >
            {/* <TileLayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" /> */}
            <TileLayer
              url={tileProvider.url}
              attribution={tileProvider.attribution}
            />

            <Query
              query={GET_MC_VEHICLES_QUERY}
              variables={{
                lat: this.state.region.lat,
                lng: this.state.region.lng,
              }}
            >
              {({ data, loading, error }) => {
                if (loading) return <LoadingIndicator />
                if (error) return <p>Error: ${error.message}</p>

                return (
                  data &&
                  data.vehicles.map((bike, i) => (
                    <McMarker
                      key={bike.id}
                      providerSlug={bike.provider.slug}
                      position={[bike.lat, bike.lng]}
                      clickHandler={e => this.handleMarkerClick(e, bike)}
                    />
                  ))
                )
              }}
            </Query>

            <DefaultMarker
              // key={'x32'}
              // position={[52, 13.4]}
              clickHandler={() =>
                // console.log("cklick from defaultmap on marker")
                this.handleMarkerClick()
              }
            />
            <MapItemDetails
              activeVehicle={this.state.activeVehicle}
              currentNetworkDetails={this.state.currentNetworkDetails}
              isvisible={this.state.isNwDetailsVisible}
              setDetailVisibility={e => this.setDetailVisibility()}
              moredetails={this.state.activeNetwork}
              activeStation={this.state.activeStation}
              selectedCity={this.state.selectedCity}
              // providerImage={this.props.providerImages.nodes.filter(item => item.name === this.state.activeVehicle.provider.slug)}
            />
          </Map>
        )}

        {/* <div class="debug" style={{ backgroundColor: "#ddd", minHeight: 100 }}>
          <h3>Debug</h3>
          <div>
            <span>Lat: {this.state.region.lat}</span>
            <span>Lng: {this.state.region.lng}</span>
          </div>
        </div> */}
      </div>
    )
  }
}

export default GeneralMap