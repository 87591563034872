import React, {Component} from 'react';
import { Marker } from 'react-leaflet';
// import '../../styles/BrmIcons.scss';
const data = {
  items: [
    {
      id: 2,
      lat: 52.5074158,
      lng: 13.4136915
    },
    {
      id: 3,
      lat: 52.5084458,
      lng: 13.4136815
    },
  ]
}

export default class DefaultMarker extends Component {

  render() {


    // return (
    return data && data.items.map((item, i) => (

      <Marker
        // icon={customMarkerIcon({...bike, index: i})}
        key={item.id}
        position={[item.lat, item.lng]}
        alt={'network icon'}
        riseOnHover={true}
        onClick={e => this.props.clickHandler(e)}
        // item={item}
        />
    ));
  }
}